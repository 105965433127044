import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import PopUp from '@/common/components/PopUp';
import React from 'react';

const CancelSubscribtion = ({ handleDecison, setShow }) => {
  return (
    <PopUp setClose={setShow}>
      <h3>Do you want to cancel subscribtion at the end of the period?</h3>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
        <CustomEditButton text={'No'} onClick={() => setShow(null)} />
        <CustomDeleteButton text={'Yes'} onClick={() => handleDecison()} />
      </div>
    </PopUp>
  );
};

export default CancelSubscribtion;
