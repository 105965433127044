import Android1 from './images/Android_1_150_DE.png';
import Android2 from './images/Android_Dodaj_150_DE.png';
import Ios1 from './images/IOS_1_150_DE.png';
import Ios2 from './images/IOS_Dodaj_150_DE.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

export default {
  paywall: {
    title: 'Vyberte si plán, aby ste mohli pokračovať v učení',
    buy: 'Kúpiť',
    savePayment: 'Bezpečné platby poskytuje',
    skip: 'Preskočiť',
    pickPlan: 'Vyberte si plán',
    endPlanFirstPart: 'Váš prístup skončí o',
    endPlanSecondPart: 'dní.',
    endPlanAditionalPart: 'deň.',
  },
  paymentStatus: {
    veryfication: 'Platba sa ešte spracováva...',
    title: 'Platba dokončená',
    success: 'úspešná!',
    failure: 'neúspešná!',
    subtitle: 'Čoskoro budete presmerovaní na prihlasovaciu stránku.',
    textInfo: `Vaša platba sa práve spracováva. Počas niekoľkých minút dostanete e-mail s registračným heslom. Skontrolujte si prosím svoju doručenú poštu a aj priečinok spam. Na uľahčenie vyhľadávania odporúčame zadať do vyhľadávacieho poľa slovo "synthiai". V prípade akýchkoľvek problémov nás prosím kontaktujte na adrese: contact@synthiaiapp.com.`,
    login: 'Prejsť na prihlásenie',
  },
  register: {
    steps: {
      register: 'Registrácia',
      package: 'Balíček',
      summarize: 'Zhrnutie',
      step: 'Krok ',
    },
    stepOne: {
      password: 'Heslo',
      errorGoogle: 'Prihláste sa pomocou svojej e-mailovej adresy',
      title: 'Registrácia',
      nameAndSurname: 'Meno a priezvisko',
      placeholderNameAndSurname: 'napr. Adam Smith',
      email: 'E-mail',
      placeholderEmail: 'napr. priklad@synthiaiapp.com',
      password: 'Heslo',
      start: 'VYTVORIŤ ÚČET',
      subtitle:
        'Heslo musí obsahovať minimálne 8 znakov, písmená, veľké písmená a čísla.',
      passwordError1: 'Heslo musí obsahovať minimálne 8 znakov.',
      passwordError2: 'Heslo musí obsahovať minimálne 1 veľké písmeno.',
      passwordError3: 'Heslo musí obsahovať minimálne 1 malé písmeno.',
      passwordError4: 'Heslo musí obsahovať minimálne 1 číslo.',
    },
    stepTwo: {
      title: 'Vyberte si plán',
      month: 'Mesiac',
      months: 'Mesiace',
      months2: 'Mesiace',
      value: '€/Mesiac',
      pick: 'vybrať',
      nowPay: 'Zaplatiť teraz',
      saveOne: 'Ušetríte',
      buy: 'Kúpiť',
      savePayment: 'Bezpečné platby poskytuje',
    },
    stepThree: {
      title: 'Zhrnutie',
      product: 'Produkt:',
      plan: 'Synthi Ai-Balíček',
      forLong: 'Prístup na',
      data: 'Dáta:',
      nameAndSurname: 'Meno a priezvisko:',
      paymentForm: 'Spôsob platby:',
      toPay: 'Dnes zaplatiť:',
      accept: 'Súhlasím s',
      rules: 'Pravidlami',
      payingAccept: 'Platbou súhlasíte s',
      paymentRules: '"Zásadami platby PayU"',
      buyAndPay: 'KÚPIŤ A ZAPLATIŤ',
    },
  },
  login: {
    title: 'Prihlásenie',
    email: 'E-mail',
    password: 'Heslo',
    textSub: 'Zabudli ste heslo? Obnovte ho',
    restart: 'tu',
    restartPasswordText:
      'Zadajte svoju e-mailovú adresu, aby ste obnovili svoje heslo. Heslo bude odoslané na vašu e-mailovú adresu.',
    placeholder: 'Zadajte svoju e-mailovú adresu...',
    resetPassword: 'Obnoviť heslo',
    buttonText: 'Prihlásiť sa',
    errorLogin: 'Skontrolujte, či je vaše heslo alebo e-mailová adresa správna',
    passwordResterted: 'Vaše heslo bolo obnovené, skontrolujte si e-maily.',
    passwordError: 'Žiaľ, vašu e-mailovú adresu sme nenašli.',
  },
  navBar: {
    home: 'Start',
    learn: 'Učenie',
    profile: 'Profil',
  },
  home: {
    hello: 'Ahoj',
    question: 'O čom chcete dnes hovoriť?',
    answer: 'Začať rozhovor',
    forYou: 'Odporúčané pre vás',
    popularNow: 'Teraz populárne',
    doYouKnow: 'Vedeli ste, že...?',
    funFact: 'Fakt dňa',
    new: 'Nové',
    newTitle: 'Scény',
    newSubtitle:
      'Vžite sa do role a hrajte scénu s KI-tútorom na vybranú tému.',
    yourFavorite: 'Vaše obľúbené',
  },
  learn: {
    chat: {
      title: 'Chat',
      subtitle: 'Rozprávajte sa na akúkoľvek tému.',
    },
    fotoMode: {
      title: 'Foto-hádanka',
      subtitle: 'Opíšte obrázok čo najpresnejšie.',
    },
    scene: {
      title: 'Scény',
      subtitle: 'Hrajte rolu s KI-tútorom.',
      new: 'Nové',
    },
    word: {
      title: 'Slová',
      subtitle: 'Rozširujte si svoju slovnú zásobu.',
    },
    flashcard: {
      title: 'Kartičky',
      subtitle: 'Opakujte a zapamätajte si slová.',
    },
    seeMore: 'Zobraziť všetko',
  },
  common: {
    description:
      'Synthi AI - Váš inteligentný asistent na učenie anglického jazyka. Naučte sa plynule hovoriť po anglicky pomocou umelej inteligencie. Objavte nový, efektívny prístup k učeniu jazykov. Začnite ešte dnes!',
    deletedFromFavorite: 'Odstránené z obľúbených',
    addedToFavorite: 'Pridané do obľúbených',
    flashcards: 'Kartičky',
    scenes: 'Scény',
    words: 'Slová',
    or: 'alebo',
    continueWithFB: 'Pokračovať cez Facebook',
    privacyLink: '/Privacy_SK.pdf',
    termsLink: '/Terms_Conditions_SK.pdf',
  },
  profile: {
    days: ['Po', 'Ut', 'St', 'Št', 'Pi', 'So', 'Ne'],
    top: {
      profile: 'Profil',
      accessTo: 'Prístup k:',
      profileImage: 'Profilový obrázok',
      nameAndSurname: 'Meno a priezvisko',
      password: 'Heslo',
      change: 'Zmeniť',
      changeProfileImage: 'Zmeniť profilový obrázok',
      changeNameAndSurname: 'Zmeniť meno a priezvisko',
      changeNameAndSurnamePlaceHolder: 'Zadajte meno a priezvisko',
      save: 'Uložiť',
      changePassword: 'Zmeniť heslo',
      passwordInfo:
        'Heslo musí obsahovať minimálne 8 znakov, písmená, veľké písmená a čísla.',
      repeatPassword: 'Zopakujte heslo',
      passwordChanged: 'Heslo bolo zmenené',
      error: 'Ups, niečo sa pokazilo',
      nameChanged: 'Meno bolo zmenené',
      profileChanged: 'Profil bol zmenený',
    },
    plan: {
      subInfo: 'na zrušenie predplatného e-mailom contact@synthiaiapp.com',
      yourPlan: 'Váš plán',
      extend: 'Predĺžiť svoj plán',
      short_extend: 'Predĺžiť',
      currentPlan: 'Aktuálny plán:',
      activeToPlan: 'Aktívny do:',
      currentPlanLong: 'Váš aktuálny plán',
    },
    middle: {
      yourMedals: 'Vaše ocenenia',
      oneDay: 'Deň',
      twoDays: 'Dni',
      words: 'Slová',
      learningTime: 'Čas učenia',
      streak: 'Streak',
      streakRecord: 'Rekord v streaku',
      yourRecord: 'Váš rekord:',
      currentRecord: 'Aktuálny stav:',
      words: 'Slová',
      wordsInWeek: 'Počet slov za týždeň:',
    },
    bottom: {
      yourTutor: 'Váš KI-tútor',
      change: 'Zmeniť',
      logout: 'Odhlásiť sa',
      FAQ: 'FAQ',
      shareOpinion: 'Zdieľať názor',
      yourPlan: 'Váš plán',
      hobby: 'Záujmy',
      learningSetting: 'Nastavenia učenia',
      back: 'Späť',
      dayTarget: 'Denný cieľ',
      languageLevel: 'Úroveň jazyka',
      changeYourLangLevel: 'Zmeňte úroveň svojho jazyka.',
      pickNewTutor: 'Vyberte si nového tútora',
      yourDecision: 'Vaše rozhodnutie ovplyvňuje štýl a atmosféru lekcií.',
      tutroChanged: 'Tútor bol zmenený',
      error: 'Ups, niečo sa pokazilo',
      englishLevelChanged: 'Vaša úroveň bola zmenená',
      levelsOptions: [
        {
          name: 'Začiatočník A1',
          description: 'Začnite svoje dobrodružstvo s anglickým jazykom',
          value: 'basic',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Začiatočník A2',
          description: 'Chápete základné výrazy v jednoduchých rozhovoroch.',
          borderRadius: '0',
          value: 'beginner',
        },
        {
          name: 'Stredne pokročilý B1',
          description:
            'Vediete jednoduché, ale zrozumiteľné rozhovory na známe témy.',
          borderRadius: '0',
          value: 'intermediate',
        },
        {
          name: 'Stredne pokročilý B2',
          description: 'Komunikujete voľne vo väčšine situácií',
          borderRadius: '0',
          value: 'advanced',
        },
        {
          name: 'Expert C1+',
          description: 'Ovládate jazyk efektívne v zložitých kontextoch.',
          value: 'expert',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      changeDailyTarget: 'Zmeňte svoje denné cieľové učenie.',
      deletedFromFavorite: 'Odstránené z obľúbených',
      addedToFavorite: 'Pridané do obľúbených',
      lessonTime: [
        {
          name: 'Rýchla lekcia',
          dsecription: 'Perfektná voľba pre rýchle učenie',
          time: '5 minút',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Štandardná lekcia',
          dsecription: 'Skvelá voľba pre vyvážené učenie',
          time: '10 minút',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Intenzívne učenie',
          dsecription: 'Najlepšia voľba pre nadšencov',
          time: '15 minút',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      dailyTargetChanged: 'Denný cieľ bol zmenený!',
      pickIntresets: 'Vyberte si aspoň 3 záujmy.',
      activeTo: 'Aktívny plán do:',
      daysLeft: 'Zostávajúce dni v pláne:',
      opinionSubtitle:
        'Neustále zlepšujeme našu aplikáciu. Povedzte nám svoj názor na to, čo by sme mohli zmeniť alebo čo sa vám najviac páči.',
      startTyping: 'Začnite písať svoj názor',
      faq: 'FAQ',
      send: 'Odoslať',
      withProblems: 'Ak máte otázky/problémy, kontaktujte nás na:',
      contactMail: 'contact@synthiaiapp.com',
      faqConfig: [
        {
          id: 1,
          question: 'Môj zvuk nefunguje.',
          answer: `Ak máte problémy so zvukom, zvážte nasledujúce rady: 
      
              Skontrolujte povolenia zvuku v prehliadači.
      
              Uistite sa, že hlasitosť vášho zariadenia je dostatočná.
      
              Skontrolujte správne pripojenie reproduktorov/slúchadiel.
      
              Skontrolujte systémové nastavenia zvuku, aby ste sa uistili, že aplikácie nie sú stlmené.
      
              Skúste iné zariadenie alebo kontaktujte technickú podporu.
          `,
        },
        {
          id: 2,
          question: 'Nemôžem nahrávať svoj hlas.',
          answer: `Ak máte problémy s nahrávaním audia, zvážte nasledujúce rady: 
              
              Skontrolujte povolenia mikrofónu v prehliadači.
      
              Uistite sa, že mobilný prehliadač má prístup k mikrofónu.
      
              Skontrolujte správne pripojenie mikrofónu.
      
              Použite iné zariadenie alebo kontaktujte technickú podporu.
          `,
        },
        {
          id: 3,
          question: 'Môžem aplikáciu používať na viacerých zariadeniach?',
          answer: `Samozrejme, jednoducho sa prihláste na každom kompatibilnom zariadení, aby ste si mohli užiť plynulé učenie.`,
        },
        {
          id: 4,
          question: 'Môžem Synthi Ai používať offline?',
          answer: `Nie, Synthi Ai vyžaduje webový prehliadač s prístupom na internet.`,
        },
        {
          id: 5,
          question: 'Ako zmením KI-tútora?',
          answer: `Prejdite do "Tútor" v nastaveniach Synthi Ai. Objavte a vyberte si preferovaného tútora.`,
        },
        {
          id: 6,
          question: 'Ako môžem predĺžiť prístup po skončení?',
          answer: `Môžete obnoviť svoj plán po skončení prihlásením sa.`,
        },
        {
          id: 7,
          question: 'Môžem získať faktúru?',
          answer:
            'Kontaktujte nás na contact@synthiaiapp.com a poskytnite svoje IČ DPH a firemné údaje.',
        },
        {
          id: 8,
          question: 'Ako môžem kontaktovať technickú podporu?',
          answer:
            'Pošlite e-mail na contact@synthiaiapp.com a poskytnite podrobnosti o probléme a informácie o zariadení a prehliadači.',
        },
      ],
    },
    rules: 'Pravidlá',
    privacy: 'Zásady ochrany osobných údajov',
    span: ' a ',
  },
  welcome: {
    zeroScreen: {
      welcomeInApp: 'Vitajte v aplikácii Synthi AI!',
      start: 'ZAČAŤ',
      welcomeMobile: 'Vitajte',
      config: {
        chrome: {
          title: 'Nainštalujte si aplikáciu na svoje zariadenie s Androidom.',
          text1: 'Kliknite v prehliadači Chrome na',
          text1bold: ' Nastavenia',
          image1: Android1,
          text2: 'Potom kliknite na',
          text2bold: ' Pridať na domovskú obrazovku',
          image2: Android2,
          text3: 'Hotovo! Synthi AI je teraz na vašej domovskej obrazovke.',
          image3: Last,
        },
        safari: {
          title:
            'Nainštalujte si aplikáciu na svoje zariadenie so systémom iOS.',
          text1: 'Kliknite v prehliadači Safari na tlačidlo',
          text1bold: ' Zdieľať',
          image1: Ios1,
          text2: 'Potom kliknite na',
          text2bold: ' Pridať na domovskú obrazovku',
          image2: Ios2,
          text3: 'Hotovo! Synthi AI je teraz na vašej domovskej obrazovke.',
          image3: Last,
        },
      },
    },
    firstScreen: {
      title: 'Vyberte si svojho tútora!',
      subtitle: 'Vaše rozhodnutie ovplyvňuje štýl a atmosféru lekcií.',
      next: 'ĎALEJ',
    },
    secondScreen: {
      title: 'Aká je vaša aktuálna úroveň angličtiny?',
      config: [
        {
          name: 'Začiatočník A1',
          description: 'Začnite svoje dobrodružstvo s anglickým jazykom',
          value: 'basic',
        },
        {
          name: 'Začiatočník A2',
          description:
            'Rozumiete základným informáciám v jednoduchých každodenných rozhovoroch.',
          value: 'beginner',
        },
        {
          name: 'Stredne pokročilý B1',
          description:
            'Vediete jednoduché, ale zrozumiteľné rozhovory na známe témy.',
          value: 'intermediate',
        },
        {
          name: 'Stredne pokročilý B2',
          description: 'Komunikujete voľne vo väčšine situácií',
          value: 'advanced',
        },
        {
          name: 'Expert C1+',
          description: 'Ovládate jazyk efektívne v zložitých kontextoch.',
          value: 'expert',
        },
      ],
      next: 'ĎALEJ',
    },
    thirdScreen: {
      title: 'Aké sú vaše záujmy?',
      subtitle: 'Vyberte si aspoň 3 záujmy.',
      next: 'ĎALEJ',
    },
    fourthScreen: {
      title: 'Koľko minút denne sa chcete učiť?',
      subtitle: 'Vyberte si svoj denný cieľ učenia.',
      next: 'ZAČAŤ!',
      config: [
        {
          name: 'Rýchla lekcia',
          dsecription: 'Perfektná voľba pre rýchle učenie',
          time: '5 minút',
          value: 'five_minutes',
        },
        {
          name: 'Štandardná lekcia',
          dsecription: 'Skvelá voľba pre vyvážené učenie',
          time: '10 minút',
          value: 'ten_minutes',
        },
        {
          name: 'Intenzívne učenie',
          dsecription: 'Najlepšia voľba pre nadšencov',
          time: '15 minút',
          value: 'fifteen_minutes',
        },
      ],
    },
  },
  features: {
    topbar: {
      back: 'Späť',
    },
    fotoMode: {
      welcome: {
        title: 'Foto-hádanka',
        subtitle:
          'Opíšte, čo vidíte na obrázku, s čo najväčšími podrobnosťami na vybranú tému.',
        start: 'Začať!',
      },
      info: {
        startTyping: 'Píšte...',
        newTask: 'Nová úloha',
        send: 'Odoslať',
      },
    },
    flashcards: {
      title: 'Kartičky',
      subtitleTop:
        'Učte sa a zapamätajte si nové slová pomocou interaktívnych metód učenia s kartičkami.',
      addCategory: 'Pridajte svoju kategóriu',
      subtitle: 'Zadajte názov svojej kategórie',
      type: 'zadajte kategóriu...',
      add: 'Pridať',
      previous: 'Predchádzajúca karta',
      task: 'Úloha',
      changeLevel: 'Zmeňte úroveň',
      dontKnow: 'Neviem',
      know: 'Viem',
      pickPairs: 'Zostaviť páry',
      goodJob: 'Dobrá práca!',
      titleEnd: 'Vaše nasadenie je pôsobivé',
      subtitleEnd: 'Ste pripravení na ďalšie výzvy?',
      repeat: 'Opakovať kategóriu',
      other: 'Vyberte inú kategóriu',
      level: {
        title: 'Zmeňte úroveň kariet',
        beginner: ' Začiatočník A1-A2',
        intermediate: 'Stredne pokročilý B1-B2',
        advance: 'Expert C1-C2',
        change: 'Zmeniť',
      },
    },
    scene: {
      title: 'Scény',
      subtitle: 'Vžite sa do role a hrajte scénu s KI-tútorom na vybranú tému.',
      suggest: 'Navrhnite novú scénu',
      talk: 'Rozprávať',
      deletedFromFavorite: 'Odstránené z obľúbených',
      addedToFavorite: 'Pridané do obľúbených',
    },
    word: {
      title: 'Slová',
      subtitle:
        'Rozširujte si svoju slovnú zásobu v kľúčových oblastiach rozhovoru s KI-tútorom.',
      suggest: 'Navrhnite novú tému slov',
      deletedFromFavorite: 'Odstránené z obľúbených',
      addedToFavorite: 'Pridané do obľúbených',
    },
  },
  cookie: {
    title: 'Oznámenie o cookies',
    subtitle:
      'Táto webová stránka používa cookies na poskytovanie lepšieho zážitku a personalizovaného obsahu. Ak sa chcete dozvedieť viac, prečítajte si naše ',
    link: 'Zásady ochrany osobných údajov',
    accept: 'PRIJÍMAM',
    denied: 'ODMIETAM',
  },
  talkingBar: {
    allowMicrofon: 'Prosím, povoľte prístup k mikrofónu',
    typeMessage: 'Napíšte správu...',
    record: 'Nahrávať',
    recording: 'Nahrávanie',
  },
  hands: {
    giveUsInfo: 'Podeľte sa s nami o svoj názor',
    thanksForGrading: 'Ďakujeme za hodnotenie!',
    subtitle: 'Váš názor je pre nás dôležitý!',
    subtitle2: 'Povedzte nám, čo si o tejto lekcii myslíte:',
    skip: 'PRESKOČIŤ',
    send: 'ODOSLAŤ',
  },
  suggestConfig: {
    profile: {
      title: 'Podeľte sa o svoj názor!',
      subtitle:
        'Stále zlepšujeme našu aplikáciu. Povedzte nám, čo by sme mohli v aplikácii zmeniť alebo čo sa vám najviac páči.',
    },
    word: {
      title: 'Navrhnite novú tému slov',
      subtitle: '',
    },
    scene: {
      title: 'Navrhnite novú scénu',
      subtitle: '',
    },
    startTyping: 'Začnite písať',
    send: 'ODOSLAŤ',
    thx: 'Názor bol pridaný! Ďakujeme!',
    error: 'Ups, niečo sa pokazilo',
  },
  checkout: {
    emailGuessText: 'Mysleli ste',
    leftTitle: 'Vyberte si plán pre seba',
    rightTitle: 'Vyplňte údaje',
    subscribe: 'Prihlásiť sa na odber',
  },
  dailyV2: {
    hint: {
      title: 'Nápoveda',
      pick: 'Vybrať',
    },
    feedback: {
      yourMessage: 'Vaša správa',
      goodMessage: 'Správna správa',
      explanation: 'Vysvetlenie',
    },
    tutorial: {
      title: 'Ako používať chat?',
      skip: 'Preskočiť',
      next: 'ĎALEJ',
      start: 'Začať',
      screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
      config: [
        [
          { title: 'Kliknite na stlmenie zvuku Tutora', image: VolumeUp },
          { title: 'Kliknite na zdieľanie spätnej väzby', image: HandsUp },
          { title: 'Kliknite pre otvorenie návodu', image: Tutorial },
        ],
        [
          { title: 'Kliknite na opätovné prehratie', image: VolumeUpBlank },
          {
            title: 'Kliknite na zobrazenie prekladu',
            image: TranslationBlank,
          },
          { title: 'Kliknite na zobrazenie nápovedy', image: HintBlank },
          { title: 'Kliknite na nahlásenie problému', image: InfoBlank },
        ],
        [
          { title: 'Vaša odpoveď je správna', image: GreenCircle },
          {
            title: 'Kliknite na zobrazenie opravenej odpovede a vysvetlenia',
            image: YellowCircle,
          },
        ],
        [
          { title: 'Kliknite do textového poľa pre napísanie správy' },
          { title: 'Kliknite na nahrávanie správy', image: Microphone },
        ],
      ],
    },
  },
  problem: {
    title: 'Nahlásiť problém',
    subtitle: 'Opíšte problém týkajúci sa AI správy',
    send: 'Odoslať',
    messageSend: 'Správa odoslaná',
    error: 'Niečo sa pokazilo, napíšte nám :)',
  },
};
