export const PAGE_CONIG = (lang) => {
  if (lang === "pl") {
    return "https://pl.test.synthiaiapp.com/api/v1";
  }
  if (lang === "en") {
    return "https://en.test.synthiaiapp.com/api/v1";
  }

  if (lang === "de") {
    return "https://de.test.synthiaiapp.com/api/v1";
  }

  if (lang === "es") {
    return "https://es.test.synthiaiapp.com/api/v1";
  }

  return "https://test.synthiaiapp.com/api/v1";
};

