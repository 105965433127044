import React from 'react';
import styled from 'styled-components';
import IconLeft from '../images/chevron-right.png';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  align-items: center;
  min-height: 80px;
`;

const Title = styled.h3`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const RestWrappers = styled.div`
  width: 20px;
  margin: 0 16px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopSection = () => {
  return (
    <Wrapper>
      <RestWrappers>
        <img src={IconLeft} />
      </RestWrappers>
      <Title>Sport</Title>
      <RestWrappers />
    </Wrapper>
  );
};

export default TopSection;
