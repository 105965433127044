import React, { useContext } from 'react';
import { FlashcardContext } from '../contexts/FlashcardContext';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';

const BottonSectionWarpper = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 100px;
  margin-bottom: 15px;
`;

const Circle = styled.div`
  margin-top: 10px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 36px;
  cursor: pointer;
`;

const BotttomSection = () => {
  const { setCurrentView } = useContext(FlashcardContext);

  return (
    <BottonSectionWarpper>
      <Circle
        style={{ background: 'grey' }}
        onClick={() => setCurrentView((prev) => prev + 1)}
      >
        <FontAwesomeIcon icon={faClose} />
      </Circle>
      <Circle
        style={{ background: '#0FA831' }}
        onClick={() => setCurrentView((prev) => prev + 1)}
      >
        <FontAwesomeIcon icon={faCheck} />
      </Circle>
    </BottonSectionWarpper>
  );
};

export default BotttomSection;
