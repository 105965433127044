import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import Image from '../images/test-image.png';
import Voice from '../images/volume-up.svg';
import Translation from '../images/icon-translate.svg';
import Preview from '../images/preview.svg';
import Information from '../images/information.svg';
import { useSwipeable } from 'react-swipeable';
import { FlashcardContext } from '../contexts/FlashcardContext';

const CardContainer = styled.div`
  width: 300px;
  height: 380px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ece5f2;
  background: #fff;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  position: absolute;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;

const CardImage = styled.img`
  width: 170px;
  height: 170px;
  border-radius: 10px;
`;

const CardContent = styled.div``;

const CardTitle = styled.h2`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 50.4px */
  margin: 0;
`;

const CardDescription = styled.p`
  margin: 0;
  margin-top: 12px;

  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  height: 50px;
`;

const CardTranslation = styled.p`
  margin: 0;
  margin-top: 12px;
  color: var(--PodText, #7f7c85);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  height: 50px;
`;

const CardFooter = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 60px;
`;

const IconButton = styled.div`
  background: none;
  border: none;

  width: 22px;
  height: 22px;

  img {
    width: 100%;
    height: auto;
  }

  cursor: pointer;
`;

const AbsoluteIcon = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;

  img {
    width: 100%;
    height: auto;
  }
  left: 10px;
  top: 10px;
`;

const DecisionKnowBox = styled.div`
  display: inline-flex;
  transform: rotate(-15deg);
  width: 190px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 1px solid #337225;
  background: #fff;
  box-shadow: 2px 4px 0px 0px #3e540c;

  color: #1d5c0e;
  font-family: Rokkitt;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 50.4px */
`;

const DecisionDontKnowBox = styled.div`
  display: inline-flex;
  transform: rotate(15deg);
  width: 190px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 1px solid #a90202;
  background: #fff;
  box-shadow: 2px 4px 0px 0px #a90202;

  color: #a90202;
  text-align: center;
  font-family: Rokkitt;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 50.4px */
`;

const DECISIONS = {
  NONE: 'none',
  KNOW: 'know',
  DONT_KNOW: 'dont_know',
};

const Card = ({ data, nextData, nextNextData }) => {
  const { setCurrentView } = useContext(FlashcardContext);
  const [translateX, setTranslateX] = useState(0);
  const [rotateDeg, setRotateDeg] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [isSwiping, setIsSwiping] = useState(false);

  const decision = useMemo(() => {
    if (rotateDeg < -5 && translateX > 0) {
      return DECISIONS.KNOW;
    }

    if (rotateDeg > 5 && translateX < 0) {
      return DECISIONS.DONT_KNOW;
    }

    return DECISIONS.NONE;
  }, [rotateDeg]);

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      setIsSwiping(true);
      const deltaX = eventData.deltaX;
      const rotation = deltaX * -0.1;
      setTranslateX(deltaX);
      setRotateDeg(rotation);
      setOpacity(1 - Math.abs(deltaX) / 300);
    },
    onSwipedLeft: () => {
      if (rotateDeg > 5) {
        setCurrentView((prev) => (prev === 9 ? 1 : prev + 1));
      }

      setTranslateX(0);
      setRotateDeg(0);
      setOpacity(1);
    },
    onSwipedRight: () => {
      if (rotateDeg < -5) {
        setCurrentView((prev) => (prev === 9 ? 1 : prev + 1));
      }

      setTranslateX(0);
      setRotateDeg(0);
      setOpacity(1);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <>
      {nextNextData && (
        <CardContainer
          style={{
            transform: 'rotate(-2.781deg)',
          }}
        >
          <AbsoluteIcon style={{ opacity: '0.5' }}>
            <img src={Information} />
          </AbsoluteIcon>
          <CardImage src={Image} alt="Card" style={{ opacity: '0.5' }} />
          <CardContent style={{ opacity: '0.5' }}>
            <CardTitle>{nextNextData.word}</CardTitle>
            <CardDescription>{nextNextData.description}</CardDescription>
            <CardTranslation>
              {nextNextData.descriptionTranslation}
            </CardTranslation>
          </CardContent>
          <CardFooter style={{ opacity: '0.5' }}>
            <IconButton>
              <img src={Voice} />
            </IconButton>
            <IconButton>
              <img src={Translation} />
            </IconButton>
            <IconButton>
              <img src={Preview} />
            </IconButton>
          </CardFooter>
        </CardContainer>
      )}
      {nextData && (
        <CardContainer
          style={{
            transform: 'rotate(2.515deg) ',
          }}
        >
          <AbsoluteIcon>
            <img src={Information} style={{ opacity: '0.5' }} />
          </AbsoluteIcon>
          <CardImage src={Image} alt="Card" style={{ opacity: '0.5' }} />
          <CardContent style={{ opacity: '0.5' }}>
            <CardTitle>{nextData.word}</CardTitle>
            <CardDescription>{nextData.description}</CardDescription>
            <CardTranslation>{nextData.descriptionTranslation}</CardTranslation>
          </CardContent>
          <CardFooter style={{ opacity: '0.5' }}>
            <IconButton>
              <img src={Voice} />
            </IconButton>
            <IconButton>
              <img src={Translation} />
            </IconButton>
            <IconButton>
              <img src={Preview} />
            </IconButton>
          </CardFooter>
        </CardContainer>
      )}

      <CardContainer
        {...handlers}
        style={{
          transform: `translateX(${translateX}px) rotate(${-rotateDeg}deg)`,
          transition: isSwiping
            ? 'none'
            : 'transform 0.3s ease, opacity 0.3s ease',
          background:
            decision == DECISIONS.KNOW
              ? '#F9FBF5'
              : decision == DECISIONS.DONT_KNOW
              ? '#F3F3F3'
              : '#fff',

          border:
            decision == DECISIONS.KNOW
              ? '1px solid #8C9A69'
              : decision == DECISIONS.DONT_KNOW
              ? '1px solid #ECE5F2'
              : '1px solid #ece5f2',
        }}
      >
        <AbsoluteIcon>
          <img src={Information} />
        </AbsoluteIcon>
        <CardImage src={Image} alt="Card" />
        <CardContent>
          <CardTitle>{data.word}</CardTitle>
          <CardDescription>{data.description}</CardDescription>
          <CardTranslation>{data.descriptionTranslation}</CardTranslation>
        </CardContent>
        <CardFooter>
          <IconButton>
            <img src={Voice} />
          </IconButton>
          <IconButton>
            <img src={Translation} />
          </IconButton>
          <IconButton>
            <img src={Preview} />
          </IconButton>
        </CardFooter>
        {decision == DECISIONS.KNOW && (
          <DecisionKnowBox
            style={{ position: 'absolute', top: '55%', left: '15%' }}
          >
            Umiem
          </DecisionKnowBox>
        )}
        {decision == DECISIONS.DONT_KNOW && (
          <DecisionDontKnowBox
            style={{ position: 'absolute', top: '55%', right: '15%' }}
          >
            Nie umiem
          </DecisionDontKnowBox>
        )}
      </CardContainer>
    </>
  );
};

export default Card;
