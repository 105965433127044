import React, { useState } from 'react';
import TopSection from './components/TopSection';
import { FlashcardContext } from './contexts/FlashcardContext';
import BotttomSection from './components/BotttomSection';
import styled from 'styled-components';
import Card from './components/Card';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 60px);

  overflow: hidden;
`;

const CONFIG = [
  {
    word: 'apple',
    translation: 'jabłko',
    description:
      'A round fruit that is typically red, green, or yellow, commonly eaten raw or used in cooking.',
    descriptionTranslation:
      'Okrągły owoc, który jest zazwyczaj czerwony, zielony lub żółty, najczęściej spożywany na surowo lub wykorzystywany w gotowaniu.',
  },
  {
    word: 'house',
    translation: 'dom',
    description:
      'A building for human habitation, providing shelter and a place to live.',
    descriptionTranslation:
      'Budynek przeznaczony do zamieszkania przez ludzi, zapewniający schronienie i miejsce do życia.',
  },
  {
    word: 'book',
    translation: 'książka',
    description:
      'A collection of written or printed pages bound together, containing a story, information, or knowledge.',
    descriptionTranslation:
      'Zbiór zapisanych lub wydrukowanych stron złączonych razem, zawierający historię, informacje lub wiedzę.',
  },
  {
    word: 'water',
    translation: 'woda',
    description:
      'A transparent, tasteless liquid that is essential for all living organisms to survive.',
    descriptionTranslation:
      'Przezroczysta, bezsmakowa ciecz, która jest niezbędna do przetrwania wszystkich organizmów żywych.',
  },
  {
    word: 'dog',
    translation: 'pies',
    description:
      'A domesticated carnivorous mammal known for loyalty and companionship, often kept as a pet.',
    descriptionTranslation:
      'Udomowiony ssak mięsożerny znany z lojalności i towarzystwa, często trzymany jako zwierzę domowe.',
  },
  {
    word: 'car',
    translation: 'samochód',
    description:
      'A vehicle with four wheels, used for transportation on roads.',
    descriptionTranslation:
      'Pojazd z czterema kołami, używany do transportu po drogach.',
  },
  {
    word: 'sun',
    translation: 'słońce',
    description:
      'The star at the center of our solar system that provides light and heat to the Earth.',
    descriptionTranslation:
      'Gwiazda znajdująca się w centrum naszego układu słonecznego, dostarczająca Ziemi światło i ciepło.',
  },
  {
    word: 'moon',
    translation: 'księżyc',
    description:
      'The natural satellite of the Earth, visible at night, reflecting sunlight.',
    descriptionTranslation:
      'Naturalny satelita Ziemi, widoczny w nocy, odbijający światło słoneczne.',
  },
  {
    word: 'sky',
    translation: 'niebo',
    description:
      'The expanse of air over the Earth, visible as a blue canopy during the day.',
    descriptionTranslation:
      'Przestrzeń powietrzna nad Ziemią, widoczna jako niebieska kopuła w ciągu dnia.',
  },
  {
    word: 'tree',
    translation: 'drzewo',
    description:
      'A perennial plant with an elongated trunk, supporting branches and leaves.',
    descriptionTranslation:
      'Wieloletnia roślina o wydłużonym pniu, wspierająca gałęzie i liście.',
  },
];

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const NewFlashcardV3 = ({}) => {
  const [currentFlashcard, setCurrentFlashcard] = useState(0);

  console.log(CONFIG[currentFlashcard]);

  return (
    <>
      <FlashcardContext.Provider
        value={{ setCurrentView: setCurrentFlashcard }}
      >
        <Wrapper>
          <TopSection />
          <CardWrapper>
            <Card
              data={CONFIG[currentFlashcard]}
              nextData={CONFIG[currentFlashcard + 1]}
              nextNextData={CONFIG[currentFlashcard + 2]}
            />
          </CardWrapper>
          <div style={{ flex: 1 }} />
          <BotttomSection />
        </Wrapper>
      </FlashcardContext.Provider>
    </>
  );
};

export default NewFlashcardV3;
