import React, { createContext, useMemo } from 'react';
import { getNavigationPathApp } from '../functions/getNavigationPathApp';

export const LanguageContext = createContext();

export const LANG_OPTIONS = {
  polish: 'pl',
  english: 'en',
  spanish: 'es',
  german: 'de',
  croatian: 'hr',
  italian: 'it',
  romanian: 'ro',
  slovak: 'sk',
  czech: 'cs',
};

const getLangFromSubdomain = () => {
  const host = window.location.host;
  let parts = host.split('.');

  if (Object.values(LANG_OPTIONS).includes(parts[0])) {
    return parts[0];
  }

  // let userLang = navigator.language || navigator.userLanguage;

  // if (Object.values(LANG_OPTIONS).includes(userLang)) {
  //   window.location.replace(`https://${userLang}.app.synthiaiapp.com`);

  //   return userLang;
  // } else {

  window.location.replace(getNavigationPathApp('pl.app.synthiaiapp.com'));

  //   return 'pl';
  // }
};

const LanguageContextProvider = ({ children }) => {
  const lang = useMemo(() => getLangFromSubdomain(), []);

  return (
    <LanguageContext.Provider value={{ lang: lang }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
